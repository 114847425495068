custome max duration body[data-aos-duration='5000'] [data-aos],
[data-aos][data-aos][data-aos-duration='5000'] {
  transition-duration: 5000ms;
}

[data-aos] {

  body[data-aos-easing="cbz"] &,
  &[data-aos][data-aos-easing="cbz"] {
    transition-timing-function: cubic-bezier(.250, .250, .750, .750);
  }
}

.custom-tooltip {
  --bs-tooltip-bg: var(--surface2-dark);
}

:root {
  color-scheme: dark;

  --brand: var(--brand-dark);
  --text1: var(--text1-dark);
  --text2: var(--text2-dark);
  --surface1: var(--surface1-dark);
  --surface2: var(--surface2-dark);
  --surface3: var(--surface3-dark);
  --surface4: var(--surface4-dark);
  --surface-shadow: var(--surface-shadow-dark);
  --shadow-strength: var(--shadow-strength-dark);
}

:root {
  --nav-header-height: 3rem;

  --nav-hue: 174;
  --nav-sat: 63%;
  --nav-first-color: rgb(169, 255, 202);
  ;
  --nav-first-color-alt: hsl(var(--nav-hue), var(--nav-sat), 36%);
  --nav-title-color: hsl(var(--nav-hue), 12%, 15%);
  --nav-text-color: hsl(var(--nav-hue), 8%, 35%);
  --nav-body-color: hsl(var(--nav-hue), 100%, 99%);
  --nav-container-color: #FFF;

  --nav-body-font: 'Open Sans', sans-serif;
  --nav-h1-font-size: 1.5rem;
  --nav-normal-font-size: .938rem;
  --nav-tiny-font-size: .625rem;

  --nav-z-tooltip: 10;
  --nav-z-fixed: 100;
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;

    --brand: var(--brand-dark);
    --text1: var(--text1-dark);
    --text2: var(--text2-dark);
    --surface1: var(--surface1-dark);
    --surface2: var(--surface2-dark);
    --surface3: var(--surface3-dark);
    --surface4: var(--surface4-dark);
    --surface-shadow: var(--surface-shadow-dark);
    --shadow-strength: var(--shadow-strength-dark);
  }
}

[color-scheme="dark"] {
  color-scheme: dark;

  --brand: var(--brand-dark);
  --text1: var(--text1-dark);
  --text2: var(--text2-dark);
  --surface1: var(--surface1-dark);
  --surface2: var(--surface2-dark);
  --surface3: var(--surface3-dark);
  --surface4: var(--surface4-dark);
  --surface-shadow: var(--surface-shadow-dark);
  --shadow-strength: var(--shadow-strength-dark);
}

html {
  --brand-hue: 200;
  --brand-saturation: 100%;
  --brand-lightness: 50%;
  --brand-dark: hsl(var(--brand-hue) calc(var(--brand-saturation) / 2) calc(var(--brand-lightness) / 1.5));
  --text1-dark: hsl(var(--brand-hue) 15% 85%);
  --text2-dark: hsl(var(--brand-hue) 5% 65%);
  --surface1-dark: hsl(var(--brand-hue) 10% 10%);
  --surface2-dark: hsl(var(--brand-hue) 10% 15%);
  --surface3-dark: hsl(var(--brand-hue) 5% 20%);
  --surface4-dark: hsl(var(--brand-hue) 5% 25%);
  --surface-shadow-dark: var(--brand-hue) 50% 3%;
  --shadow-strength-dark: .8;
}

* {
  scrollbar-width: thin;
  scrollbar-color: gray #333;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #333;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid #333;
}

html {
  height: 100%;
  background-color: hsl(200, 25%, 90%);
  background-color: var(--surface1);
  color: hsl(200, 100%, 10%);
  color: var(--text1);
}

body,
html {
  padding: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;

  background: radial-gradient(rgba(170, 170, 170, 0.09)8%, transparent 8%);
  background-position: 0% 0%;
  background-size: 5vmin 5vmin;
}

a {
  font-weight: 500;
  color: #a299ff;
  text-decoration-skip-ink: auto;
  background-color: transparent;
  cursor: pointer;
}


#hero-box {
  position: relative;
  text-align: center;
}

#hero-tag {
  width: 100%;
  position: absolute;
  top: 30%;
  margin: 0 auto;
  text-align: center;
}

#hero-vid {
  height: 100vh;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.pitch-box {
  width: 100%;
  max-width: 1000px;
  padding: 60px 16px 16px 16px;
  margin: 0 auto;
}

.text-light {
  color: hsl(200, 100%, 10%);
  color: var(--text1)
}


p.text-light {
  font-weight: 200
}

.text-dim {
  color: hsl(200, 30%, 30%);
  color: var(--text2);
}

.surface1 {
  background-color: hsl(200, 25%, 90%);
  background-color: var(--surface1);
  color: hsl(200, 30%, 30%);
  color: var(--text2);
}

.surface2 {
  background-color: hsl(200, 20%, 99%);
  background-color: var(--surface2);
  color: hsl(200, 30%, 30%);
  color: var(--text2);
}

.surface3 {
  background-color: hsl(200, 20%, 92%);
  background-color: var(--surface3);
  color: hsl(200, 100%, 10%);
  color: var(--text1);
}

.surface4 {
  background-color: hsl(200, 20%, 85%);
  background-color: var(--surface4);
  color: hsl(200, 100%, 10%);
  color: var(--text1);
}


.drop-shadow {
  border: 1px solid hsla(200, 10%, 50%, 0.15);
  border: 1px solid hsl(var(--brand-hue) 10% 50% / 15%);
  box-shadow: 0 1rem .5rem -.5rem;
  box-shadow:
    0 2.8px 2.2px hsla(200, 10%, 20%, calc(.02 + .03)),
    0 6.7px 5.3px hsla(200, 10%, 20%, calc(.02 + .01)),
    0 12.5px 10px hsla(200, 10%, 20%, calc(.02 + .02)),
    0 22.3px 17.9px hsla(200, 10%, 20%, calc(.02 + .02)),
    0 41.8px 33.4px hsla(200, 10%, 20%, calc(.02 + .03)),
    0 100px 80px hsla(200, 10%, 20%, .02);
  box-shadow:
    0 2.8px 2.2px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + .03)),
    0 6.7px 5.3px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + .01)),
    0 12.5px 10px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + .02)),
    0 22.3px 17.9px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + .02)),
    0 41.8px 33.4px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + .03)),
    0 100px 80px hsl(var(--surface-shadow) / var(--shadow-strength));
}

.circle-radio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 42px;
  height: 42px;
  border-radius: 50%
}

.article-body {
  font-size: 17px;
  margin: 0 auto;
  color: white;
  max-width: 700px;
  line-height: 2rem;
  text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.05);
  background: hsl(0, 0%, 69%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.article-drop-caps:first-letter {
  float: left;
  font-weight: bold;
  color: white;
  font-size: 280%;
  line-height: 50%;
  margin: 0 0.2rem 0.4rem -0.6rem;
}

.hover-gradient {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.hover-gradient:hover:before {
  background-position: 100% 100%;
  transform: scale(1.08, 1.03);
}


.hover-gradient:before {
  background: linear-gradient(130deg,
      transparent 0% 33%,
      rgb(98, 0, 234) 66%,
      rgb(236, 64, 122) 83.5%,
      rgb(253, 216, 53) 100%);
  background-position: 0% 0%;
  background-size: 300% 300%;
  content: "";
  height: 100%;
  left: 0px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  transition: background-position 350ms ease, transform 350ms ease;
  width: 100%;
  z-index: 1;
}

.container-circle {
  text-align: center;
}

div.circTxt {
  display: inline-block;
  margin-bottom: 4px;
  color: whitesmoke;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.techstack {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 64px;
}

.techstack>div>div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.techstack>div>div>i {
  position: relative;
  z-index: 4;
}

.ts-icon {
  font-size: 42px;
  color: whitesmoke;
}

.scale-on-hover {
  transition: transform 250ms;
}

.scale-on-hover:hover {
  transform: scale(1.1);
  z-index: 20;
  position: relative;
}

.accordion-button {
  color: white;
}

.accordion-flush .accordion-item {
  background: #ffffff00;
  color: #fff;
}

.accordion {
  --bs-accordion-color: #fff;
  --bs-accordion-bg: #ffffff00;
  --bs-accordion-active-color: #000000;
  --bs-accordion-active-bg: #ffffff;
}

.accordion-body {
  color: hsl(0, 0%, 69%);
  max-width: 350px;
}



.highlight-hover::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.highlight-hover:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.highlight-hover::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(226, 245, 52);
  z-index: -1;
  transition: transform .3s ease;
}

.highlight-hover {
  position: relative;

}




.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__img {
  width: 32px;
  border-radius: 50%;
}

.nav__logo {
  font-size: 26px;
  font-family: monospace;
  letter-spacing: 3px;
  vertical-align: middle;
  margin-left: 8px;
  font-weight: 900;
  color: white;
  line-height: 2px;
  vertical-align: text-bottom;
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    bottom: -2px;
    left: 2px;
    background-color: var(--surface2);
    box-shadow: 0 -1px 12px hsla(var(--nav-hue), var(--nav-sat), 15%, 0.15);
    width: 99%;
    height: 4rem;
    padding: 0 1rem;
    display: grid;
    align-content: center;
    border-radius: 1.25rem 1.25rem 0 0;
    transition: .4s;
  }
}

.nav__list,
.nav__link {
  display: flex;
}

.nav__link {
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  color: white;
  font-weight: 600;
}

.nav__list {
  justify-content: space-around;
  margin-bottom: 0;
}

.nav__name {
  font-size: var(--nav-tiny-font-size);
}

.nav__icon {
  font-size: 1.5rem;
}

.active-link {
  position: relative;
  color: var(--nav-first-color);
  transition: .3s;
}


.scroll-header {
  box-shadow: 0 1px 12px hsla(var(--nav-hue), var(--nav-sat), 15%, 0.15);
}

@media screen and (max-width: 320px) {
  .nav__name {
    display: none;
  }
}

@media screen and (min-width: 576px) {
  .nav__list {
    justify-content: center;
    column-gap: 3rem;
  }
}

@media screen and (min-width: 767px) {
  body {
    margin: 0;
  }

  .section {
    padding: 7rem 0 2rem;
  }

  .nav {}

  .nav__img {
    display: none;
  }

  .nav__icon {
    display: none;
  }

  .nav__name {
    font-size: var(--nav-normal-font-size);
  }

  .nav__link:hover {
    color: var(--nav-first-color);
  }

  .active-link::before {
    content: '';
    position: absolute;
    background-color: var(--nav-first-color);
  }


}

@media screen and (min-width: 1024px) {}

.parallax {}

.btn.active {
  background: #3d4143 !important;
}

.btn.active:hover {
  background: #3d4143 !important;
}